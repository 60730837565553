import { Connection, PublicKey, clusterApiUrl } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import * as anchor from '@coral-xyz/anchor';
import { Program, AnchorProvider } from '@coral-xyz/anchor';
import { Wallet } from '@coral-xyz/anchor/dist/cjs/provider';
import liquidStakingIdl from '../idls/liquid_staking-idl.json';
import { JewelswapLiquidStaking } from '../types/jewelswap_liquid_staking';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { DAY_IN_SECOND, YEAR_IN_DAY } from '../config';
import { wlValidators } from '../data';

export const connection = new Connection(process.env.REACT_APP_RPC_URL || clusterApiUrl('devnet'), "confirmed");
export const programId = new PublicKey(liquidStakingIdl.metadata.address);

export const globalStatePubkey = PublicKey.findProgramAddressSync(
    [Buffer.from('global-state')],
    programId
)[0];

export const reserveVault = PublicKey.findProgramAddressSync(
    [
        Buffer.from('reserve-vault'),
        globalStatePubkey.toBuffer(),
    ],
    programId
)[0];

export const useContractInteractor = () => {
    const wallet = useWallet();
    const provider = new AnchorProvider(connection, wallet as Wallet, { commitment: 'processed' });
    const program = new Program(liquidStakingIdl as anchor.Idl, programId, provider) as unknown as Program<JewelswapLiquidStaking>;

    return { provider, program };
};

export const getBlockTime = async () => {
    const slot = await connection.getSlot();
    const currentBlockTime = await connection.getBlockTime(slot);

    return currentBlockTime || 0;
}

export const getBalance = async (address: PublicKey): Promise<number> => {
    return await connection.getBalance(address);
}

export const getTokenBalance = async (
    address: PublicKey,
    tokenMint: PublicKey
): Promise<number> => {
    const tokenAccount = await getAssociatedTokenAddress(tokenMint, address);
    const tokenAccountInfo = await connection.getAccountInfo(tokenAccount);
    if (tokenAccountInfo) {
        const tokenBalance = await connection.getTokenAccountBalance(tokenAccount);
        return Number(tokenBalance.value.amount);
    } else {
        return 0;
    }
}

export const getTokenSupply = async (
    tokenMint: PublicKey
): Promise<number> => {
    try {
        const tokenSupply = await connection.getTokenSupply(tokenMint);
        return Number(tokenSupply.value.amount);
    } catch {
        return 0;
    }
}

export const HOUR_IN_SECONDS = 3600;
export const DAY_IN_SECONDS = 86400;
export const SECOND_TO_MILLISECONDS = 1000;

export const getApy = async (
    jwlsolReserve: number,
    sjwlsolReserve: number,
    contractDeployedAt: number,
): Promise<number> => {
    let apy = 0;
    console.log("apy");
    //if (sjwlsolReserve > 0 && jwlsolReserve > sjwlsolReserve) {
        console.log("apycalc");
        console.log(Date.now());
        // const currentBlockTime = await getBlockTime();
        // console.log(currentBlockTime);
        // const increasedAmount = jwlsolReserve - sjwlsolReserve;
        // const elapsedTime = currentBlockTime - contractDeployedAt;
        // console.log("apycalc" + increasedAmount);
    //     apy = 10000 / jwlsolReserve / (elapsedTime / DAY_IN_SECOND) * YEAR_IN_DAY * 100;
    //    console.log(apy);
       const currentTime = new Date();
  const startDate = new Date(currentTime.getTime() - 1000 * 3600 * 24 * 7).getTime();
  const endDate = new Date(currentTime.getTime() - 1000 * 3600 * 23).getTime();
  const passedMilliseconds  = endDate - startDate;
  const passedHours = passedMilliseconds / HOUR_IN_SECONDS / SECOND_TO_MILLISECONDS;
  console.log(passedMilliseconds);
  console.log("rewards");
  console.log(jwlsolReserve);
  const totalRewardsValue = 10000;
  const weekly_rewards = 8000 
const weeks_per_year = 52 

const weekly_yield = weekly_rewards / jwlsolReserve
  apy = (1 + weekly_yield) ** weeks_per_year - 1
  const apy_percentage = apy * 100
  return apy_percentage;
  
        console.log("apycalc" + apy);
   // }
    console.log(sjwlsolReserve);
    console.log(jwlsolReserve);
    console.log(apy);
    return apy;
}

export const getValidatorName = (voteAccount: string): string | undefined => {
    const validator = wlValidators.find(x => x.voteAccount === voteAccount);
    return validator?.name
}

export const sliderValueLabelFormat = (value: number) => {
    return `${value} %`;
};

export const convertSecondsToDateTime = (secondsToConvert: number) => {
    const days = Math.floor(secondsToConvert / (3600 * 24));
    secondsToConvert %= 3600 * 24;
    const hours = Math.floor(secondsToConvert / 3600);
    secondsToConvert %= 3600;
    const minutes = Math.floor(secondsToConvert / 60);
    const seconds = Math.floor(secondsToConvert % 60);

    return `${days.toFixed()}d ${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(seconds).padStart(2, '0')}s`;
};
